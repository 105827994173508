import "./index.css";
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useState } from "react";
import { CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import { MakeStripePurchase } from "../../Services/Profile";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { TextField } from '@mui/material';
import { Padding } from "@mui/icons-material";
import Button from '@material-ui/core/Button';

const stripePromise = loadStripe('pk_test_51NG3fqKsOuXXDZeSK4rQR8uSvzphBgQq8tdBvTRItLZdFZzqCHpK8BYtUYgclbPuMvdyq5vBEWBQB02agpwpFeNW00dcKfmEO3');

export const PaymentMethodComponent = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const packageId = searchParams.get('packageId');
    const packageDetails = {
        1: {
            amount: 1500
        },
        2: {
            amount: 2500
        },
        3: {
            amount: 4500
        }
    };
    const body = {
        amount: packageDetails[packageId].amount
    }

    return (
        <>
            <div class="body-wrapper">
                <div class="content">
                    <section class="section-padding">
                        <Elements stripe={stripePromise}>
                            <PaymentForm body={body} />
                        </Elements>
                    </section>
                </div>
            </div>

        </>
    );
}

const PaymentForm = ({ body }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };
    const stripe = useStripe();
    const elements = useElements();

    const [error, setError] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (error) {
            setError(error.message);
        } else {
            MakeStripePurchase({ ...body, pmId: paymentMethod.id }).then((res) => {
                setSnackbarSeverity("success");
                setSnackbarMessage(res?.data?.message);
                setSnackbarOpen(true);
            })
        }
    };

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <form onSubmit={handleSubmit}>
            
            <div className="form-title">
                    <h1>Payment details</h1>
            </div>
            
            <div className="form-container">
                <div className="card-container">
                    <label>Card Number</label>
                    <div className="card-element-container">
                        <CardNumberElement />
                    </div>
                </div>

                <div className="exp-cvc">
                    
                    <div className="exp">
                        <label>Card Number</label>
                        <div className="card-element-container">
                            <CardExpiryElement />
                        </div>
                    </div>
                    
                    <div className="cvc">
                        <label>Card Number</label>
                        <div className="card-element-container">
                            <CardCvcElement />
                        </div>
                    </div>
                
                </div>

                {error && <div>{error}</div>}
                <div className="linkbtn">
                    <Button 
                        style={{borderRadius: '50px', padding: '22.5px', width: "55%", marginLeft: 0}}
                        color="primary" 
                        variant="contained" 
                        fullWidth 
                        type="submit"
                        className="btn btn-primary">
                        <span className="login-text">Pay Now</span>
                    </Button>
                </div>
            </div>
            </form>
        </>
    );
};