import group778 from "../../Assets/Images/group778.png";
import group779 from "../../Assets/Images/group779.png";
import group780 from "../../Assets/Images/group780.png";
import group781 from "../../Assets/Images/group781.png";
import group782 from "../../Assets/Images/group782.png";
import group783 from "../../Assets/Images/group783.png";
import group784 from "../../Assets/Images/group784.png";
import group785 from "../../Assets/Images/group785.png";
export const ArchiveComponent = () => {
    return (
        <div class="body-wrapper">

        <div class="content">
            <section class="section-padding">
                <h2 class="title">Archive</h2>
                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-reload-tab" data-toggle="pill" data-target="#pills-reload" type="button" role="tab" aria-controls="pills-reload" aria-selected="true"><i class="icon-Icon-open-reload"></i></button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-calender-tab" data-toggle="pill" data-target="#pills-calender" type="button" role="tab" aria-controls="pills-calender" aria-selected="false"><i class="icon-Icon-awesome-calendar-day"></i></button>
                    </li>

                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-reload" role="tabpanel" aria-labelledby="pills-reload-tab">
                        <div class="row m-0">
                            <div class="video-thumb">
                                <div class="icons">
                                    <a href="#"><i class="icon-Union-5"></i></a>
                                    <a href="#"><i class="icon-Group-609"></i></a>
                                    <a href="#" data-toggle="modal" data-target="#social"><i class="icon-Icon-awesome-share-alt"></i></a>
                                </div>
                                <img src={group778} alt="" />
                                <div class="center">
                                    <a href="#"><i class="icon-Icon-ionic-ios-videocam"></i></a>
                                </div>
                                <span class="date">11/15/2022</span>
                            </div>
                            <div class="video-thumb">
                                <div class="icons">
                                    <a href="#"><i class="icon-Union-5"></i></a>
                                    <a href="#"><i class="icon-Group-609"></i></a>
                                    <a href="#" data-toggle="modal" data-target="#social"><i class="icon-Icon-awesome-share-alt"></i></a>
                                </div>
                                <img src={group779} alt="" />
                                <div class="center">
                                    <a href="#"><i class="icon-Icon-ionic-ios-videocam"></i></a>
                                </div>
                                <span class="date">11/15/2022</span>
                            </div>
                            <div class="video-thumb">
                                <div class="icons">
                                    <a href="#"><i class="icon-Union-5"></i></a>
                                    <a href="#"><i class="icon-Group-609"></i></a>
                                    <a href="#" data-toggle="modal" data-target="#social"><i class="icon-Icon-awesome-share-alt"></i></a>
                                </div>
                                <img src={group780} alt="" />
                                <div class="center">
                                    <a href="#"><i class="icon-Icon-ionic-ios-videocam"></i></a>
                                </div>
                                <span class="date">11/15/2022</span>
                            </div>
                            <div class="video-thumb">
                                <div class="icons">
                                    <a href="#"><i class="icon-Union-5"></i></a>
                                    <a href="#"><i class="icon-Group-609"></i></a>
                                    <a href="#" data-toggle="modal" data-target="#social"><i class="icon-Icon-awesome-share-alt"></i></a>
                                </div>
                                <img src={group781} alt="" />
                                <div class="center">
                                    <a href="#"><i class="icon-Icon-ionic-ios-videocam"></i></a>
                                </div>
                                <span class="date">11/15/2022</span>
                            </div>
                            <div class="video-thumb">
                                <div class="icons">
                                    <a href="#"><i class="icon-Union-5"></i></a>
                                    <a href="#"><i class="icon-Group-609"></i></a>
                                    <a href="#" data-toggle="modal" data-target="#social"><i class="icon-Icon-awesome-share-alt"></i></a>
                                </div>
                                <img src={group782} alt="" />
                                <div class="center">
                                    <a href="#"><i class="icon-Icon-ionic-ios-videocam"></i></a>
                                </div>
                                <span class="date">11/15/2022</span>
                            </div>
                            <div class="video-thumb">
                                <div class="icons">
                                    <a href="#"><i class="icon-Union-5"></i></a>
                                    <a href="#"><i class="icon-Group-609"></i></a>
                                    <a href="#" data-toggle="modal" data-target="#social"><i class="icon-Icon-awesome-share-alt"></i></a>
                                </div>
                                <img src={group783} alt="" />
                                <div class="center">
                                    <a href="#"><i class="icon-Icon-ionic-ios-videocam"></i></a>
                                </div>
                                <span class="date">11/15/2022</span>
                            </div>
                            <div class="video-thumb">
                                <div class="icons">
                                    <a href="#"><i class="icon-Union-5"></i></a>
                                    <a href="#"><i class="icon-Group-609"></i></a>
                                    <a href="#" data-toggle="modal" data-target="#social"><i class="icon-Icon-awesome-share-alt"></i></a>
                                </div>
                                <img src={group784} alt="" />
                                <div class="center">
                                    <a href="#"><i class="icon-Icon-ionic-ios-videocam"></i></a>
                                </div>
                                <span class="date">11/15/2022</span>
                            </div>
                            <div class="video-thumb">
                                <div class="icons">
                                    <a href="#"><i class="icon-Union-5"></i></a>
                                    <a href="#"><i class="icon-Group-609"></i></a>
                                    <a href="#" data-toggle="modal" data-target="#social"><i class="icon-Icon-awesome-share-alt"></i></a>
                                </div>
                                <img src={group785} alt="" />
                                <div class="center">
                                    <a href="#"><i class="icon-Icon-ionic-ios-videocam"></i></a>
                                </div>
                                <span class="date">11/15/2022</span>
                            </div>

                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-calender" role="tabpanel" aria-labelledby="pills-calender-tab">
                        <div id='calendar'></div>
                    </div>
                </div>
            </section>
            <script src="./assets/js/mlib.js"></script>
            <script src="./assets/js/functions.js"></script>
            <script>
                {/* {document.addEventListener('DOMContentLoaded', function() {
                    var calendarEl = document.getElementById('calendar');

                    var calendar = new FullCalendar.Calendar(calendarEl, {
                        timeZone: 'UTC',
                        initialView: 'multiMonthYear',
                        editable: true,
                        events: 'https://fullcalendar.io/api/demo-feeds/events.json'
                    });

                    calendar.render();
                    $('button[data-toggle="pill"]').on('shown.bs.tab', function(e) {
                        calendar.render();
                    });
                    
                })} */}
            </script>
        </div>
    </div>
    )
}