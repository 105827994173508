import React, { useEffect } from "react";
import { Layout } from "../Layout/Layout";
import driverImg from "../../Assets/Images/driver.png"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import maskGroup43 from "../../Assets/Images/maskGroup43.png"
import ellipse46 from "../../Assets/Images/ellipse46.png"
import ellipse515 from "../../Assets/Images/ellipse515.png"
import group778 from "../../Assets/Images/group778.png"
import group779 from "../../Assets/Images/group779.png";
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import VideocamIcon from '@mui/icons-material/Videocam';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { GetDriver, GetVideos } from '../../Services/Auth';
import APP_ROLES from '../../SharedComponents/role';
import "./FleetDriver.css"
import { VideoList } from "../../Components/VideoPlayer/List";
export const ViewFleetDetails = ({ role }) => {
    const [videos, setVideos] = React.useState([]);
    const [driver, setDriver] = React.useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = location.state || '';
    useEffect(()=>{
        GetFleetVideos();
        setDriver(data)
        console.log('data',data)
    },[])
    const options = { 
        month: '2-digit', 
        day: '2-digit', 
        year: 'numeric' 
      };
      const navigationURL=(data)=>{
       if(role === APP_ROLES.ADMIN){
        navigate("/superadmin/packages");
       }
       else if(role === APP_ROLES.FLEET_MANAGER){
        navigate("/packages");
       }
      }
      const GetFleetVideos = async () => {
        try {
          const res = await GetVideos(APP_ROLES.FLEET_MANAGER);
          if (+res?.code === 200) {
            // Handle success
            setVideos(res?.data);
          } else if (+res?.code !== 200) {
            setVideos([]);
          }
        } catch (error) {
          // Handle error
          setVideos([]);
        }
      };
      const navigateToEdit = ()=>{
        navigate("/edit-fleetdriver",{state :{id:driver?.id}})
      }
  return (
    <Layout role={role}>
     <div class="body-wrapper">

<div class="content">
    <section class="section-padding">
        <div class="text-right edit-delete">
            <a onClick={()=> navigateToEdit()} class="btn btn-primary mr-2">Edit</a>
            <a href="#" class="btn btn-secondary">Delete</a>
        </div>
        <div class="info">
            <img class="proimg" src={driverImg} alt="" />
            <h2 class="title mb-2">{driver?.user_detail?.name}</h2>
            <p class="mb-1"><b>{driver?.email}</b></p>
            <div class="row">
                <div class="col-lg-8">
                    <p>
                    {driver?.user_detail?.description}
                    </p>
                </div>
                <div class="col-lg-4 text-lg-right">
                    <a style={{fontSize:'12px'}} 
                    onClick={navigationURL}
                    class="btn btn-primary">Increase Storage Pack</a>
                </div>
            </div>

            <h4>{driver?.user_detail?.vehicle_make} Detail</h4>

            <h6 class="mb-1">Model</h6>
            <p>{driver?.user_detail?.vehicle_model}</p>

            <div class="row my-4">
                <div class="col-lg-3">
                    <h6 class="mb-1">Purchase Date</h6>
                    <p>{new Date(driver?.user_detail?.created_at)?.toLocaleDateString(undefined, options)}</p>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-1">Vehicle Name</h6>
                    <p>{driver?.user_detail?.vehicle_name}</p>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-1">Driving Habit</h6>
                    <p>{driver?.user_detail?.driving_habit}</p>
                </div>

            </div>
            <div class="row m-0">
            <VideoList videos={videos}/>
            </div>
        </div>
    </section>
    <script src="./assets/js/mlib.js"></script>
    <script src="./assets/js/functions.js"></script>
</div>
</div>
    </Layout>
  );
};
