import appConfig from "../SharedComponents/appConfig";
import APP_ROLES from "../SharedComponents/role";

export const Login = (role, body) => {
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  };
  return fetch(`${APP_URL}${BASE_URL}${appConfig.LOGIN}`, requestOptions).then(
    (response) => {
      return response.json();
    }
  );
};
export const Profile = (role, body) => {
  const token =localStorage.getItem('token')
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json",'Authorization': `Bearer ${token}` },
    body,
  };
  return fetch(`${APP_URL}${BASE_URL}${appConfig.PROFILE}/update`, requestOptions).then(
    (response) => {
      return response.json();
    }
  );
};
export const GoogleLogin = (role, body) => {
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  };
  console.log(requestOptions);
  return fetch(`${APP_URL}${BASE_URL}${appConfig.GOOGLE_LOGIN}`, requestOptions).then(
    (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );
};
export const FacebookLogin = (role, body) => {
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  };
  console.log(requestOptions);
  return fetch(`${APP_URL}${BASE_URL}${appConfig.FACEBOOK_LOGIN}`, requestOptions).then(
    (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );
};
export const Register = (role, body) => {
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.REGISTER}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};
export const UploadProfile = (role, body, params) => {
  const token =localStorage.getItem('token')
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json",'Authorization': `Bearer ${token}` },
    body,
  };
  return fetch(`${APP_URL}${BASE_URL}${appConfig.UPLOAD}?type=${params}`, requestOptions).then(
    (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );
};

export const ForgotPassword = (role, body) => {
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.FORGOT_PASSWORD}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};
export const SetPassword = (role, body) => {
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.SET_PASSWORD}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};
export const VerifyOTP = (role, body) => {
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.VERIFY_OTP}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};
export const InviteDriver = (role, body) => {
  const token =localStorage.getItem('token')
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json",'Authorization': `Bearer ${token}` },
    body,
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.INVITE_DRIVER}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};

export const SendNotification = (role, body) => {
  const token =localStorage.getItem('token')
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json",'Authorization': `Bearer ${token}` },
    body,
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.NOTIFICATIONS}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};

export const GetDriver = (role) => {
  const token =localStorage.getItem('token')
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json",'Authorization': `Bearer ${token}` },
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.DRIVERS}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};
export const GetVideos = (role) => {
  const token =localStorage.getItem('token')
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json",'Authorization': `Bearer ${token}` },
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.VIDEOS}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};
export const CreateDriver = (role,body) => {
  const token =localStorage.getItem('token')
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json",'Authorization': `Bearer ${token}` },
    body
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.CREATE_DRIVER}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};
export const EditDriver = (role,body,id) => {
  const token =localStorage.getItem('token')
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json",'Authorization': `Bearer ${token}` },
    body
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.CREATE_DRIVER}/${id}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};
export const GetDriverById = (role,id) => {
  const token =localStorage.getItem('token')
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json",'Authorization': `Bearer ${token}` }
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.DRIVER_DETAIL}/${id}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};
export const GetRequests = (role) => {
  const token =localStorage.getItem('token')
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json",'Authorization': `Bearer ${token}` }
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.TICKETS}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};

export const ApproveRequests = (role,id) => {
  const token =localStorage.getItem('token')
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL =
    role === APP_ROLES.ADMIN
      ? appConfig.ADMIN_BASE_URL
      : appConfig.FLEET_BASE_URL;
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json",'Authorization': `Bearer ${token}` }
  };

  return fetch(
    `${APP_URL}${BASE_URL}${appConfig.APPROVE}${appConfig.TICKET}/${id}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });
};