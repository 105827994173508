import visa from "../../Assets/Images/visa.png";
import paypal from "../../Assets/Images/paypal.png";
import pay from "../../Assets/Images/pay.png";
import "./index.css"
import { Link } from "react-router-dom";

export const PackagesComponent = () => {
    return (
        <div className="body-wrapper">

        <div className="content">
            <section className="section-padding">
                <h2 className="title">Packages</h2>
                <div className="row">
                    <div className="col-lg-3 custom-column-gap">
                        <div className="packlist">
                            <div className="package-title">
                                <h6>SILVER PACKAGE</h6>
                            </div>
                            <div className="description-container">
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>  
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>              
                            </div>
                            <div className="package-price-container">
                                <h2 className="price"><sup><bold>$</bold></sup>24.99</h2>
                                <p>Monthly</p>
                            </div>
                            <Link to={'/payment-method?packageId=1'}><a className="btn btn-primary btn-sm w-50 package-btn">Choose</a></Link>
                        </div>
                    </div>
                    <div className="col-lg-3 custom-column-gap">
                        <div className="packlist">
                            <div className="package-title">
                                <h6>GOLD PACKAGE</h6>
                            </div>
                            <div className="description-container">
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>  
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>              
                            </div>
                            <div className="package-price-container">
                                <h2 className="price"><sup>$</sup>24.99</h2>
                                <p>Monthly</p>
                            </div>
                            <Link to={'/payment-method?packageId=1'}><a className="btn btn-primary btn-sm w-50 package-btn">Choose</a></Link>
                        </div>
                    </div>
                    <div className="col-lg-3 custom-column-gap">
                        <div className="packlist">
                            <div className="package-title">
                                <h6>PREMIUM PACKAGE</h6>
                            </div>
                            <div className="description-container">
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>  
                                <div class="container">
                                    <div style={{paddingRight: '12px'}} class="tick-icon">&#10003;</div>
                                    <div class="content-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. asd asdsad assadsad</p>
                                    </div>
                                </div>              
                            </div>
                            <div className="package-price-container">
                                <h2 className="price"><sup>$</sup>24.99</h2>
                                <p>Monthly</p>
                            </div>
                            <Link to={'/payment-method?packageId=1'}><a className="btn btn-primary btn-sm w-50 package-btn">Choose</a></Link>
                        </div>
                    </div>                                        
                </div>
                <div className="text-start mt-5">
                    <p classNameName="grey">If you have any questions, please feel free to contact us.</p>
                    <div className="d-flex justify-content-start align-items-center grey">
                        We accept 
                        <img className="mx-2" src={visa} alt="..." />
                        <img className="mx-2" src={paypal} alt="..." />
                        <img className="" src={pay} alt="..." />
                    </div>
                </div>
            </section>
             <script src="./assets/js/mlib.js"></script>
            <script src="./assets/js/functions.js"></script>
        </div>
    </div>
    )
}