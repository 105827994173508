import React from "react";
import './index.css'
import { VideoList } from "../VideoPlayer/List";
import { useEffect } from "react";
import { GetVideos } from "../../Services/Auth";
import APP_ROLES from "../../SharedComponents/role";
export const HomeComponents = ({role}) => {
  const [videos, setVideos] = React.useState([]);
   
    useEffect(()=>{
        GetFleetVideos()
    },[])
    const GetFleetVideos = async () => {
        try {
          const res = await GetVideos(APP_ROLES.FLEET_MANAGER);
          if (+res?.code === 200) {
            // Handle success
            setVideos(res?.data);
          } else if (+res?.code !== 200) {
            setVideos([]);
          }
        } catch (error) {
          // Handle error
          setVideos([]);
        }
      };
    return (
        <div class="body-wrapper">
        <div class="content">
            <section class="section-padding">
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <h2 class="title">Recent Videos</h2>
                    </div>
                    <div class="col-lg-6 text-right">
                        <a href="#" class="fz-30"><i class="icon-menu_navigation_direction_arrow_location_icon"></i></a>
                    </div>
                </div>
                <VideoList videos={videos}/>
            </section>
            <script src="./assets/js/mlib.js"></script>
            <script src="./assets/js/functions.js"></script>
        </div>
    </div>
    )
}