import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
} from "@mui/material";
import AddTaskIcon from '@mui/icons-material/AddTask';
import "./Requests.css"
import { ApproveRequests, GetRequests } from "../../Services/Auth";
import APP_ROLES from "../../SharedComponents/role";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from '@material-ui/core';
import check from "../../Assets/Images/check.svg";
import DialogContent from "@mui/material/DialogContent";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  
  const useStyles = makeStyles({
    outlinedInput: {
      borderRadius: '20px', // Adjust the value as per your preference
    },
    inputLabel: {
      padding: 0, // Remove padding around the label
    },
  });
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
const TicketRequests = ({role}) => {
    const [requests, setRequests] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const handleSnackbarClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setSnackbarOpen(false);
    };
   
    useEffect(()=>{
        GetFleetVideos()
    },[])
    const GetFleetVideos = async () => {
        try {
          const res = await GetRequests(APP_ROLES.ADMIN);
          if (+res?.code === 200) {
            // Handle success
            setRequests(res?.data);
          } else if (+res?.code !== 200) {
            setRequests([]);
          }
        } catch (error) {
          // Handle error
          setRequests([]);
        }
      };
      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
     
  const handleApprove = async (id) => {
    // Handle edit action here
    try {
        const res = await ApproveRequests(APP_ROLES.ADMIN,id);
        if (+res?.code === 200) {
          // Handle success
          console.log(requests)
          GetFleetVideos()
          handleClickOpen()
        } else if (+res?.code !== 200) {
            setSnackbarSeverity("error");
            setSnackbarMessage(res?.message);
            setSnackbarOpen(true);
        }
      } catch (error) {
        // Handle error
        setSnackbarSeverity("error");
        setSnackbarMessage(error);
        setSnackbarOpen(true);
      }
    };

  return (
    <div className="body-wrapper padding">
    <div className="content">
    <TableContainer  component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requests?.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row?.requester_id}</TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell>
              <Chip label={row?.status} color={row?.status === "APPROVED" ? "success":"warning"} />
              </TableCell>
              <TableCell>
                <IconButton
                  color="primary"
                  onClick={() => handleApprove(row?.id)}
                >
                  {row?.status === "APPROVED" ? <AddTaskIcon style={{color:"green"}}/> :<AddTaskIcon /> }
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                      >
                        <DialogContent style={{backgroundColor: "black", padding: '0'}}>
                          <div className="modal-dialog modal-dialog-centered" style={{backgroundColor: "grey"}}>
                            <div className="modal-content" style={{backgroundColor: 'transparent', padding: "0", paddingTop: "20px"}}>
                              {/* <div className="modal-header">
                                  <a 
                                  style={{display:'flex',justifyContent:'end',alignItems:'center',width:'100%'}}
                                  onClick={handleClose}>
                                  <CloseIcon
                                  sx={{
                                      color: (theme) => theme.palette.error.main,
                                    }}
                                  />
                                  </a>
                              </div> */}
                              <div className="modal-body">
                                <div className="text-center mb-2" style={{position: "relative", backgroundColor: '#E8F1FE',width: "280px", height: "200px" ,margin: "0", borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}>
                                  <div>
                                      <img
                                        className="mb-4"
                                        style={{postion: "absolute", marginTop: '-50px', backgroundColor: "#E8F1FE",
                                        border: '1px solid black',
                                        padding: "10px",
                                        borderRadius: "50px"}}
                                        src={check}
                                        width="100"
                                        alt="logo"
                                      />
                                  </div>
                                  <div className="modal-header" style={{position: "absolute", padding: '0', marginTop: '-65px', marginLeft: '245px'}}>
                                      <a 
                                      style={{display:'flex',justifyContent:'end',alignItems:'center',width:'100%'}}
                                      onClick={handleClose}>
                                      <CloseIcon
                                      sx={{
                                          color: (theme) => theme.palette.error.main,
                                        }}
                                      />
                                      </a>
                                  </div>
                                  <div>
                                      <h4 className="mb-0" >Approved</h4>
                                      <p className="mb-4" style={{marginTop: '10px', marginBottom: '0'}}>
                                        Successfully approved this request.
                                      </p>
                                  </div>
                                  <div style={{padding: '0px', width: "100%"}}>
                                      <a  onClick={handleClose} className="btn btn-primary" style={{borderRadius: '0', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}>Ok</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </DialogContent>
    </BootstrapDialog>
    <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
            >
              <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
    </div>
    </div>
  );
};

export default TicketRequests;
