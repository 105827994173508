export const GetProfile = () => {
  const token = localStorage.getItem('token');
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL = '/user/profile';
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
  };
  return fetch(`${APP_URL}${BASE_URL}`, requestOptions).then(
    (response) => {
      return response.json();
    }
  );
};

export const MakeStripePurchase = (body) => {
  const token = localStorage.getItem('token');
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL = '/user/stripe-purchase';
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
    body: JSON.stringify({pmId: body.pmId, amount: body.amount})
  };
  return fetch(`${APP_URL}${BASE_URL}`, requestOptions).then(
    (response) => {
      return response.json();
    }
  );
};

export const GetVideos = () => {
  const token = localStorage.getItem('token');
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL = '/user/videos';
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
  };
  return fetch(`${APP_URL}${BASE_URL}`, requestOptions).then(
    (response) => {
      return response.json();
    }
  );
};

export const UpdateProfile = (payload) => {
  const token = localStorage.getItem('token');
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL = '/user/profile/update';
  const requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
    body: JSON.stringify(payload),
  };
  return fetch(`${APP_URL}${BASE_URL}`, requestOptions).then(
    (response) => {
      return response.json();
    }
  );
};

export const UploadProfilePic = (pic) => {
  const token = localStorage.getItem('token');
  const APP_URL = process.env.REACT_APP_DOMAIN;
  const BASE_URL = '/user/upload?type=PROFILE';
  const requestOptions = {
    method: "POST",
    headers: { 'Authorization': `Bearer ${token}` },
    body: pic,
  };
  return fetch(`${APP_URL}${BASE_URL}`, requestOptions).then(
    (response) => {
      return response.json();
    }
  );
};

