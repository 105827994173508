import React from "react";
import "../Login/index.css";

export const ErrorPage = () => {
  return (
    <div className="card">
      <h1>Page Not Found</h1>
    </div>
  );
};
