import React, { useEffect, useState } from 'react'
import profile from '../../Assets/Images/profile.png'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import NotificationBell from './NotificationBell.js';
import { GetProfile } from '../../Services/Profile';

export const Header = () => {
    const [apiPayload, setApiPayload] = useState({});

    const fetchProfile = async () => {
      const profileData = await GetProfile();
      return profileData;
    }

    useEffect(() => {
      fetchProfile().then((data) => {
      setApiPayload(data);
    });
    }, []);

    return (
        <header class="header-main">
        <div class="top-bar">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="navbar-right">
                  <ul class="nav-menu">
                    <li class="nav-item dropdown notification">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{marginRight: "40px", padding: "0"}}
                      >
                      <div>
                        <NotificationBell 
                          notificationCount={3} />
                      </div>
                        {/* <i class="icon-Icon-awesome-bell"></i>
                        <span class="badge badge-pill badge-danger">5</span> */}
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul>
                          <li>
                            <a href="#">
                              <i class="icon-Icon-awesome-bell"></i> Lorem ipsum
                              donet In eu purus libero.{" "}
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="icon-Icon-awesome-bell"></i> Lorem ipsum
                              donet In eu purus libero.{" "}
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="icon-Icon-awesome-bell"></i> Lorem ipsum
                              donet In eu purus libero.{" "}
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="icon-Icon-awesome-bell"></i> Lorem ipsum
                              donet In eu purus libero.{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
      
                    <li class="nav-item dropdown account-item">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={apiPayload?.data?.user_detail?.profile_img || profile} alt="" />
                        <span style={{marginLeft: "5px"}}>
                          <h4>{apiPayload?.data?.user_detail?.name}</h4>
                          <p>{apiPayload?.data?.email}</p>
                        </span>
                        <div style={{marginLeft: "5px"}}>
                          <KeyboardArrowDownSharpIcon />
                        </div>
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul>
                          <li>
                            <a href="#" class="">
                              My Profile
                            </a>
                          </li>
                          <li>
                            <a href="#" class="">
                              Settings
                            </a>
                          </li>
                          <li>
                            <a href="#" class="logout-modal">
                              Signout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
}