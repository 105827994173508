import React from 'react';
import { styled } from '@mui/system';
import { MuiOtpInput } from 'mui-one-time-password-input'


export const CustomOTPInput = styled(MuiOtpInput)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden', // Hide the inner inputs that extend beyond the container
    '& .MuiOutlinedInput-root': {
      flex: 1,
      '&:not(:first-child)': {
        marginLeft: theme.spacing(15),
      },
      '& .MuiOutlinedInput-input': {
        borderRadius: '45px', // Apply border radius to the most outer container
        backgroundColor: '#f2f2f2',
        color: 'rgba(0, 0, 0, 0.5)',
        font: 'normal normal 600 30px/38px Quicksand',
        fontWeight: 'bold',
        fontSize: '40px',
        border: 'none',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Remove the border from the div encapsulating the input
      },
      '& .MuiOutlinedInput-input::after': {
         // Set the desired opacity for the selected/highlighted text
      },
  }));