import ReactPlayer from "react-player";
import React, { useState } from "react";
import { Player } from "./Player";
import Editor from "./Editor";
import VideocamIcon from "@mui/icons-material/Videocam";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

export function VideoList({ videos }) {
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [timings, setTimings] = useState([]);
  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  };
  const handleClick = (event, video) => {
    setOpenModal(true);
    setTitle(video.title);
    setUrl(video?.path);
  };
  const handleStateFromChild = (event, video) => {
    setOpenModal(false);
    setTitle("");
    setUrl("");
    setOpenEdit(false);
  };

  const handleEdit = (event, video) => {
    setOpenEdit(true);
    setTitle(video.title);
    setUrl(video?.path);
  };

  return (
    <div>
      <div className="grid grid-cols-3 mt-6">
        {videos?.map((video) => (
          <div key={video?.id} class="card video-card">
            <div
              class="video-thumb"
              onClick={(event) => handleClick(event, video)}
            >
              <div class="icons">
                <a href="#" data-toggle="modal" data-target="#social">
                  <ShareOutlinedIcon />
                </a>
              </div>
              <ReactPlayer
                url={video?.path}
                controls={false}
                width="640"
                height={"fit-content"}
              />
              <div class="center">
                <a>
                  <VideocamIcon style={{ fontSize: "30px", color: "white" }} />
                </a>
              </div>
            </div>
            <div class="card-body-content">
              <div className="title-style">
                <div className="font-bold text-xl mb-2">{video.title}</div>
                <span class="date">
                  {new Date(video?.created_at)?.toLocaleDateString(
                    undefined,
                    options
                  )}
                </span>
              </div>
              <a
                onClick={(event) => handleEdit(event, video)}
                class="btn btn-primary btn-sm w-100"
              >
                Edit Video
              </a>
            </div>
          </div>
        ))}
      </div>
      {openModal && (
        <Player title={title} url={url} change={handleStateFromChild} />
      )}
      {openEdit && (
        <Editor
          videoUrl={url}
          timings={timings}
          setTimings={setTimings}
          change={handleStateFromChild}
          title={title}
        />
      )}
    </div>
  );
}
